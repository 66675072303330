<template>
    <div class="fill-specific__section">
        <div class="fill-specific__section-title">
            Сконфигурируйте помещения
        </div>
        <div class="fill-specific__name-rooms --config">
            <template v-for="(category, index) in config?.room_groups" :key="index">
                <template v-for="(room, roomIndex) in category.rooms" :key="room.id">
                    <div v-for="(field, fieldIndex) in room.client_rooms" :key="field.id" :id="`config${field.id}`"
                         class="fill-specific__config">
                        <div class="fill-specific__check-block-title mt48" v-if="config.materials_for_room[room.id]">
                            {{ field.name?.length ? field.name : '???' }}
                        </div>
                        <template v-for="check in config.materials_for_room[room.id]?.material_types">
                            <room-check
                                v-model="field.materials_for_room"
                                :id="`${field.id}${check.type_id}`"
                                :label="check.type_admin_name"
                                :is-disabled="check.required"
                                :required="check.required"
                                :typeId="check.type_id"
                                :items="check.items"
                                :checked="check.checked"
                                :isEditing="isEditing"
                                :isConfigEdited="isConfigEdited"
                            ></room-check>
                        </template>
                    </div>
                </template>
            </template>
            <div class="choose-one">Выберите хотя бы одно помещение</div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import InputStyled from '@/components/forms/InputStyled.vue';
import RoomCheck from '@/components/forms/RoomCheck.vue';

export default {
    name: 'NameRooms',
    props: {},
    components: {
        InputStyled,
        RoomCheck,
    },
    data: function () {
        return {};
    },
    mounted() {

    },
    computed: {
        ...mapState({
            config: state => state.configurator.config,
            isEditing: state => state.configurator.isEditing,
            isConfigEdited: state => state.configurator.isConfigEdited,
        }),

    },
    methods: {
    },
};
</script>
