<template>
    <div class="switch" :class="{'active': value}" @click="toggleTumbler">
        <input type="checkbox" :name="name" v-model="value">
        <label :for="name">
            <div class="icon">
                <span class="i-wrapper">
                    <span :class="icon"></span>
                </span>
            </div>
        </label>
    </div>
</template>


<script>
    import { mapState } from 'vuex'

    export default {
        name: "Switch",
        components: {

        },
        props: {
            name: {
               default() {
                   return '';
               }
            },
            value: {
               default() {
                   return false;
               }
            },
            icon: {
                default(){
                    return '';
                }
            },
            isDisabled: {
                default(){
                    return false;
                }
            }


        },
        data() {
            return {

            }
        },
        mounted() {
        },
        beforeDestroy(){
        },
        watch: {

        },
        methods: {
            toggleTumbler(){
                this.$store.commit('tumblers/toggleTumbler', this.name);
                this.$emit('change')
            },

        }
    }
</script>

<style scoped>

</style>
