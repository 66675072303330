<template>
    <div class="fill-specific__section">
        <div class="fill-specific__section-title">
            <span v-if="!isCheck">Проверьте всё ещё раз</span>
            <span v-else>{{ currentItem.name }}</span>
        </div>
        <div class="fill-specific__table">
            <div class="fill-specific__left" v-if="config">
                <div class="title">помещения</div>
                <template v-for="(category, index) in config.room_groups" :key="index">
                    <template v-for="(room, roomIndex) in category.rooms" :key="`room-${roomIndex}`">
                        <div v-for="(field, fieldIndex) in room.client_rooms" :key="field.id" :id="`table${field.id}`" class="room">
                            <div class="room__name" v-if="field.name.length">{{ field.name || '???' }}</div>
                            <div class="room__options" v-if="field.materials_for_room.length">
                            <div v-for="option in field.materials_for_room" class="room__option">
                                <div v-if="checkTypeExists(room.id, option.material_type_id)">
                                    {{ getCheckTypeName(room.id, option.material_type_id) }}
                                    {{ getTumblerName(room.id, option.material_type_id, option.material_selector_id) }}
                                </div>
                            </div>
                            </div>
                            <div class="room__options" v-else>
                            <div class="room__option">нет опций</div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
            <div class="fill-specific__right" v-if="isCheck">
                <div class="title">особенности объекта</div>
                <div class="room" v-for="item in config?.no_room">
                    <div class="room__name">{{ item.name }}</div>
                    <div class="room__options">
                        <template v-for="check in item.materials">
                            <div v-if="check.checked">
                                <template v-for="selector in check.selectors">
                                    <div class="room__option" v-if="selector.checked">{{selector.name}}</div>
                                </template>
                                <div class="room__option" :class="{'--sub': check.material_type_id === 62}">{{ check.material_type }}</div>
                            </div>
                        </template>
                        <div class="room__option --nothing">нет опций</div>
                    </div>
                </div>
            </div>
            <div class="fill-specific__right" v-else>
                <div class="title">особенности объекта</div>
                <div class="room" v-for="item in config?.no_room">
                    <div class="room__name">{{ item.name }}</div>
                    <div class="room__options">
                        <template v-for="check in item.materials">
                            <template v-for="selector in check.selectors">
                                <div class="room__option" v-if="checkSelectorExist(selector.id)">{{selector.name}}</div>
                            </template>
                            <div class="room__option" :class="{'--sub': check.material_type_id === 62}" v-if="checkMaterialExist(check.material_type_id)">{{ check.material_type }}</div>
                        </template>
                        <div class="room__option --nothing">нет опций</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import CheckboxComp from '@/components/forms/CheckboxComp.vue';
    export default {
        name: "ObjectFeatures",
        props: {
            isCheck: false,
        },
        components: {
            CheckboxComp,
        },
        data: function(){
            return {
                
            }
        },
        mounted(){
        },
        computed: {
            ...mapState({
                config: state => state.configurator.config,
                features: state => state.configurator.features,
                currentItem: state => state.configurator.currentItem,
                isEditing: state => state.configurator.isEditing,
            }),

        },
        methods: {
            ...mapActions('configurator', [
                'addField', 'send', 'save'
            ]),
            addField(room, index) {
                this.$store.commit('configurator/addField', { room, index });
            },
            checkTypeExists(roomId, typeId) {
                let material_types = this.config.materials_for_room[roomId]?.material_types;
                if (typeof material_types === 'object' && material_types !== null) {
                    return material_types.some(item => item.type_id === typeId);
                }
                return false;
            },
            getCheckTypeName(roomId, typeId) {
                let material_types = this.config.materials_for_room[roomId]?.material_types;
                if (typeof material_types === 'object' && material_types !== null) {
                    const foundItem = material_types.find(item => item.type_id === typeId);
                    return foundItem ? foundItem.type_admin_name : null; 
                }
                return null;
            },
            getTumblerName(roomId, typeId, selectorId) {
                let material_types = this.config.materials_for_room[roomId]?.material_types.find(item => item.type_id === typeId);
                let items = material_types.items;
                if (items && items.length) {
                    const materialType = items.find(material => material.id === selectorId);
                    return materialType.name;
                }
            },
            sendSpecific() {
                if (!this.isEditing) {
                    this.send();
                } else {
                    this.save();
                }
            },
            checkMaterialExist(id) {
                return this.features.some(obj => obj.material_type_id === id);
            },
            checkSelectorExist(id) {
                return this.features.some(obj => obj.material_selector_id === id);
            },
        },
    }
</script>
