<template>
    <div class="content">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">Ожидают передачи</div>
            </div>
            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t">Ваши объекты, по которым необходимо передать заказчикам ЗемсДизайн</span>
            </div>
        </div>

        <nothing-block v-if="!requests.length && !isLoad"/>
        <section class="container">
            <requests-list :status="'give-design'"/>
        </section>

        <request-popups></request-popups>

    </div>
</template>
<script>
    import {mapState, mapActions} from 'vuex';
    import Tooltip from '@/components/Tooltip.vue';
    import Popup from '@/components/modal/Popup.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import RequestsList from "@/components/requests/RequestsList";
    import NothingBlock from '@/components/NothingBlock.vue';
    import RequestPopups from '@/components/RequestPopups.vue';

    export default {
        name: "GiveDesign",
        props: {

        },
        components: {
            RequestPopups,
            Tooltip,
            Popup,
            Tumbler,
            RequestsList,
            NothingBlock,
        },
        data: function(){
            return {}
        },
        mounted() {
            if(!(this.user.groupCode == 'R'
                || this.user.groupCode == 'K'
                || this.user.groupCode == 'P'
                || this.user.groupCode == 'T'
                || this.user.groupCode == 'TD'
                || this.user.groupCode == 'D'
            )) {
                this.$store.commit('auth/setIsNoSuccessSection', true, { root: true });
                this.$store.commit('auth/setLoaded', true, { root: true });
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                requests: state => state.requests.items,
                isLoad: state => state.requests.isLoad,
                user: state => state.auth.user,
            }),
        },
    }
</script>
