<template>
    <div>
        <div class="popup__header">
            <div class="title">Оценка нашей работы</div>
            <div class="text-color-white-trans text-bold mt4">{{ item.name }}</div>
        </div>


        <div class="popup__content">
            <div class="text-size-l text-bold">Насколько готовы нас рекомендовать?</div>

            <div class="mt16 mb40">
                <Tumbler :options="optionsTumbler" :elem-width="31"
                    v-model="form.rate.value" 
                    @change="form.rate.error=false" 
                    :error="form.rate.error"></Tumbler>
            </div>
            <div class="text-size-l text-bold mb12">Комментарий заказчика</div>
            <textarea-field :class="'full show-placeholder'"
                            v-model="form.text.value"
                            :error-text="''"
                            :label="''"
                            :is-error="form.text.error"
                            @focus="form.text.error = false"
                            placeholder="Подробно опросите заказчика и запишите ответы дословно..."
                            :add-style="{minHeight: 80 + 'px'}"></textarea-field>
            <div class="df df-space-between mt40">
                <submit-btn @click="send" tag="button" :type="btnType">
                    Подтвердить
                </submit-btn>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import Tumbler from '@/components/Tumbler.vue';
    import TextareaField from '@/components/forms/TextareaField.vue';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';

    export default {
        name: "RateOurWork",
        components: {
            Tumbler,
            SubmitBtn,
            TextareaField
        },
        methods: {
            ...mapActions('requests', [
                'getNpsRate'
            ]),
            removeError(form) {
                form.error = false;
            },
            send(){
                let error = false;
                for(let field in this.form){
                    let errorField = false;
                    this.form[field].error = false;

                    if(this.form[field].value.toString().length === 0 || this.form[field].value === false){
                        errorField = true;
                    }
                    if(errorField){
                        error = true;
                        setTimeout(() => {
                            this.form[field].error = true;
                        });
                    }
                }
                if(!error) {
                    let params = {
                        action: 'RateOurWork',
                        id: this.item.id,
                        params: {
                            text: this.form.text.value,
                            rate: this.form.rate.value ? this.form.rate.value.id : '',
                            update: this.isNPSCollect ? 'N' : 'Y',
                        }
                    };
                    this.btnType = 'load';
                    this.$store.dispatch('requests/actionRequest', params).then(() => {
                        this.btnType = 'ok';
                        if (this.isNPSCollect) {
                            this.$store.commit('requests/deleteRequest', {id: this.item.id});
                        } else {
                            if (this.isGetRate()) {
                                this.getNpsRate();
                            }
                            if (this.isChangeTab()) {
                                this.$store.commit('requests/deleteRequest', {id: this.item.id});
                            } else {
                                this.$store.commit('requests/setItemProps', {item: this.item, props: {
                                    nps_mark: this.form.rate.value.id,
                                    nps_mark_comment: this.form.text.value,
                                }});
                            }

                        }
                        setTimeout(()=>{
                            this.$store.commit('popups/close', {name: this.popups.rateOurWork.name});
                        }, this.options.delayPopupClose);
                    },()=>{});
                }
            },
            isChangeTab () {
                if (this.form.rate.value.id === this.item.props.nps_mark_comment || this.$route.params.status === 'nps-measure-all') {
                    return false;
                } else {
                    if (this.$route.params.status === 'nps-measure-promo' && !(+this.form.rate.value.id === 9 || +this.form.rate.value.id === 10)) {
                        return true;
                    }
                    if (this.$route.params.status === 'nps-measure-neutral' && !(+this.form.rate.value.id === 8 || +this.form.rate.value.id === 7)) {
                        return true;
                    }
                    if (this.$route.params.status === 'nps-measure-critic' && !(+this.form.rate.value.id >= 1 && +this.form.rate.value.id <= 6)) {
                        return true;
                    }
                }
            },
            isGetRate() {
                if ((this.$route.params.status === 'nps-measure-promo' || this.$route.params.status === 'nps-measure-all') && !(+this.form.rate.value.id === 9 || +this.form.rate.value.id === 10)) {
                    return true;
                }
                if ((this.$route.params.status === 'nps-measure-neutral' || this.$route.params.status === 'nps-measure-all') && !(+this.form.rate.value.id === 8 || +this.form.rate.value.id === 7)) {
                    return true;
                }
                if ((this.$route.params.status === 'nps-measure-critic' || this.$route.params.status === 'nps-measure-all') && !(+this.form.rate.value.id >= 1 && +this.form.rate.value.id <= 6)) {
                    return true;
                }
            },
        },
        data: function () {
            return {
                btnType: 'normal',
                form: {
                    rate: {
                        value: false,
                        error: false,
                        repeatError: false,
                    },
                    text: {
                        value: '',
                        error: false,
                        repeatError: false,
                    },
                },
                optionsTumbler: [
                    {name: '1', id: 1},
                    {name: '2', id: 2},
                    {name: '3', id: 3},
                    {name: '4', id: 4},
                    {name: '5', id: 5},
                    {name: '6', id: 6},
                    {name: '7', id: 7},
                    {name: '8', id: 8},
                    {name: '9', id: 9},
                    {name: '10', id: 10},
                ],

            }
        },
        mounted() {
            if (this.item.props.nps_mark) {
                this.form.rate.value = {
                    id: +this.item.props.nps_mark,
                    name: this.item.props.nps_mark.toString(),
                };
                this.form.text.value = this.item.props.nps_mark_comment;
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                item: state => state.popups.curItemData,
                options: state => state.options,
            }),
            isNPSCollect() {
                return this.$route.name === 'nps-collect' || this.$route.name === 'nps-collect-current';
            },
        },
    }
</script>

<style scoped>

</style>
