<template>
    <div class="content content--no-sub">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">Загрузка команд</div>

            </div>
            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t">Подтверждённые объекты на {{ month }}</span>
            </div>
        </div>
        <section class="container">
            <div class="table --workload">
                <div class="table__header">
                    <div class="header-elem text-size-ml text-bold text-color-white text-uppercase title" style="width: 200px">
                        команда
                    </div>
                    <div class="header-elem text-size-ml text-bold text-color-white text-uppercase" style="width: 100px">
						объекты
                    </div>
					<div class="header-elem text-size-ml text-bold text-color-white text-uppercase text-right" style="width: 200px;">
						метры
                    </div>
                </div>

                <div class="table__list">
                    <div class="table__row" v-for="(item,i) in items" :key="i">
						<div class="table__item" style="width: 200px;">
                            {{ item.name }}
						</div>
						<div class="table__item"  style="width: 100px;">
                            {{ item.count }}
						</div>
						<div class="table__item text-right"  style="width: 200px;">
                            {{ item.area }}
						</div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import {mapActions, mapState} from 'vuex';

export default {
    name: "Workload",
    props: {

    },
    components: {
    },
    data: function(){
        return {
            month: ''
        }
    },
    mounted () {
        this.month  = new Date().toLocaleString('ru', {
            month: 'long',
        });
        this.getWorkload();
    },
    computed: {
        ...mapState({
            items: state => state.staff.workload,
        }),
    },
    methods: {
        ...mapActions('staff', [
            'getWorkload'
        ]),
    },
    watch: {
    },
}
</script>
