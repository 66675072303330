<template>
    <div class="content">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">{{ activeItem.title }}</div>
            </div>

            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t" v-html="activeItem.description"></span>
            </div>
        </div>

        <nothing-block v-if="!requests.length && !isLoad"/>
        <section class="container">
            <template v-if="search.isSearchPage">
                <div class="h1">Результаты поиска <a @click="openSearch()" href="javascript:void(0);">«{{search.query}}»</a></div>
                <div class="nothing-found" v-if="search.isEmpty && !search.isLoadSearch">
                    <div class="df mt32">
                        <img src="@/assets/img/nothing.svg" alt="">
                        <div class="text-bold text-uppercase text-size-ml ml16">
                            ничего не найдено
                        </div>
                    </div>
                    <button @click="openSearch()" type="button" name="button" class="btn mt32">
                        изменить запрос
                    </button>
                    <hr>
                </div>
                <div class="baza-list pb0" v-else>
                    <baza-item v-for="request in search.items" :item="request" :key="request.id"/>
                </div>
                <request-popups></request-popups>
            </template>
            <requests-list v-else-if="pageCode && !search.isSearchPage" :status="pageCode"/>
             <!-- Оценка нашей работы-->
             <popup :show="popups.rateOurWork.show"
                    :width="540"
                :name="popups.rateOurWork.name">
                <rate-our-work/>
            </popup><!-- Оценка нашей работы-->
             <popup :show="popups.npsNotCall.show"
                    :width="540"
                :name="popups.npsNotCall.name">
                <nps-not-call/>
            </popup>
            <!-- Характеристика заказчика -->
            <popup :show="popups.customerCharacter.show"
                   :width="540"
                   :name="popups.customerCharacter.name">
                <customer-character/>
            </popup>
        </section>


    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import Tooltip from '@/components/Tooltip.vue';
    import Popup from '@/components/modal/Popup.vue';
    import RateOurWork from '@/components/modal/nps/RateOurWork.vue';
    import NpsNotCall from '@/components/modal/nps/NpsNotCall.vue';
    import RequestsList from "@/components/requests/RequestsList";
    import NothingBlock from '@/components/NothingBlock.vue';
    import CustomerCharacter from '@/components/modal/requests/CustomerCharacter.vue';
    import BazaItem from '@/components/BazaItem.vue'
    import RequestPopups from '@/components/RequestPopups.vue';

    export default {
        name: "NpsCollect",
        props: {

        },
        components: {
            BazaItem,
            Tooltip,
            Popup,
            RateOurWork,
            RequestsList,
            NothingBlock,
            NpsNotCall,
            CustomerCharacter,
            RequestPopups,
        },
        data: function(){
            return {

            }
        },
        mounted() {
            if(!(this.user.groupCode == 'D'
                || this.user.groupCode == 'V'
                || this.user.groupCode == 'N'
            )) {
                this.$store.commit('auth/setIsNoSuccessSection', true, { root: true });
                this.$store.commit('auth/setLoaded', true, { root: true });
            }
            this.resetSearch();
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                menuSecond: state => state.menus.itemsSecondNps,
                requests: state => state.requests.items,
                isLoad: state => state.requests.isLoad,
                search: state => state.requests.searchNps,
                user: state => state.auth.user,
            }),
            activeItem() {
                let activeItem = this.menuSecond.find(option => option.id === this.pageCode);
                return activeItem ? activeItem : {};
            },
            pageCode() {
                return this.$route.params.status ? this.$route.params.status : 'nps-collect-new';
            },
        },
        methods: {
            fetchData () {
                //console.log('fetch');
            },
            openSearch(){
                document.getElementById('searchFieldBtnNps').click();
            },
            resetSearch() {
                this.$store.commit('requests/setIsSearchNpsPage', false);
                this.$store.commit('requests/clearListSearchNps');
            },
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
