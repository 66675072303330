<template>
    <div class="content">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">Замер НПС</div>
            </div>
            <span class="t text-center">Текущий<br>НПС {{ rateNps }}%</span>
            <div class="main-title__right">
                <Tumbler :model-value="tumbler" :options="options" :width-auto="true" :padding="12" @change="tumblerChange"></Tumbler>
            </div>
        </div>

        <nothing-block v-if="!requests.length && !isLoad"/>
        <section class="container">
            <template v-if="search.isSearchPage">
                <div class="h1">Результаты поиска <a @click="openSearch()" href="javascript:void(0);">«{{search.query}}»</a></div>
                <div class="nothing-found" v-if="search.isEmpty && !search.isLoadSearch">
                    <div class="df mt32">
                        <img src="@/assets/img/nothing.svg" alt="">
                        <div class="text-bold text-uppercase text-size-ml ml16">
                            ничего не найдено
                        </div>
                    </div>
                    <button @click="openSearch()" type="button" name="button" class="btn mt32">
                        изменить запрос
                    </button>
                    <hr>
                </div>
                <div class="baza-list pb0" v-else>
                    <baza-item v-for="request in search.items" :item="request" :key="request.id"/>
                </div>
                <request-popups></request-popups>
            </template>
            <requests-list  v-else-if="!search.isSearchPage" :status="tumbler.id ? tumbler.id : 'nps-measure-all'"/>
            <!-- Оценка нашей работы-->
            <popup :show="popups.rateOurWork.show"
                    :width="540"
                :name="popups.rateOurWork.name">
                <rate-our-work/>
            </popup>
            <!-- Характеристика заказчика -->
            <popup :show="popups.customerCharacter.show"
                   :width="540"
                   :name="popups.customerCharacter.name">
                <customer-character/>
            </popup>
        </section>


    </div>
</template>
<script>
    import {mapState, mapActions} from 'vuex';
    import Tooltip from '@/components/Tooltip.vue';
    import Popup from '@/components/modal/Popup.vue';
    import Tumbler from '@/components/Tumbler.vue';
    import RateOurWork from '@/components/modal/nps/RateOurWork.vue';
    import RequestsList from "@/components/requests/RequestsList";
    import NothingBlock from '@/components/NothingBlock.vue';
    import CustomerCharacter from '@/components/modal/requests/CustomerCharacter.vue';
    import BazaItem from '@/components/BazaItem.vue'
    import RequestPopups from '@/components/RequestPopups.vue';

    export default {
        name: "NpsMeasure",
        props: {

        },
        components: {
            RequestPopups,
            BazaItem,
            Tooltip,
            Popup,
            Tumbler,
            RateOurWork,
            RequestsList,
            NothingBlock,
            CustomerCharacter,
        },
        data: function(){
            return {
                tumbler: { name: 'все', id: 'nps-measure-all'},
            }
        },
        mounted() {
            if(!(this.user.groupCode == 'D'
                || this.user.groupCode == 'V'
                || this.user.groupCode == 'N'
                || this.user.groupCode == 'A'
                || this.user.groupCode == 'TD'
                || this.user.groupCode == 'R'
            )) {
                this.$store.commit('auth/setIsNoSuccessSection', true, { root: true });
                this.$store.commit('auth/setLoaded', true, { root: true });
            } else {
                this.resetSearch();
                if (!this.$route.params.status) {
                    this.$router.push('/nps-measure/nps-measure-all');
                    this.getNpsRate();
                } else {
                    this.getNpsRate();
                }
                this.tumbler.id = this.$route.params.status ? this.$route.params.status : 'nps-measure-all';
            }
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                requests: state => state.requests.items,
                isLoad: state => state.requests.isLoad,
                npsRate: state => state.requests.npsRate,
                search: state => state.requests.searchNps,
                user: state => state.auth.user,
            }),
            rateNps() {
                return this.ratePromo - this.rateCritic;
            },
            ratePromo() {
                if (+this.npsRate["nps-measure-all"] === 0) {
                    return 0;
                }
                return (this.npsRate["nps-measure-promo"] / this.npsRate["nps-measure-all"] * 100).toFixed(0);
            },
            rateNeutral() {
                if (+this.npsRate["nps-measure-all"] === 0) {
                    return 0;
                }
                return (this.npsRate["nps-measure-neutral"] / this.npsRate["nps-measure-all"] * 100).toFixed(0);
            },
            rateCritic() {
                if (+this.npsRate["nps-measure-all"] === 0) {
                    return 0;
                }
                return (this.npsRate["nps-measure-critic"] / this.npsRate["nps-measure-all"] * 100).toFixed(0);
            },
            options() {
                return [
                    {
                        name: `все (${this.npsRate["nps-measure-all"]} чЕЛ.)`, id: 'nps-measure-all'
                    },
                    {
                        name: `промоутеры (9/10) — ${this.npsRate["nps-measure-promo"]} чЕЛ. (${this.ratePromo}%)`,
                        id: 'nps-measure-promo'
                    },
                    {
                        name: `нейтралы (7/8) — ${this.npsRate["nps-measure-neutral"]} ЧЕЛ. (${this.rateNeutral}%)`,
                        id: 'nps-measure-neutral'
                    },
                    {
                        name: `критики (1...6) — ${this.npsRate["nps-measure-critic"]} ЧЕЛ. (${this.rateCritic}%)`,
                        id: 'nps-measure-critic'
                    },
                ];
            }
        },
        methods: {
            fetchData () {
                //console.log('fetch');
                this.getNpsRate();
            },
            tumblerChange(value) {
                this.tumbler.id = value.id;
                this.$router.push('/nps-measure/' + value.id);
            },
            ...mapActions('requests', [
                'getNpsRate'
            ]),
            openSearch(){
                document.getElementById('searchFieldBtnNps').click();
            },
            resetSearch() {
                this.$store.commit('requests/setIsSearchNpsPage', false);
                this.$store.commit('requests/clearListSearchNps');
            },
        },
        watch: {
            '$route': 'fetchData'
        },
    }
</script>
