<template>
    <div class="inp-s-wrap inp-select full" ref="inpWrap" :class="[{ opened: focused, active: showLabel, error: isError, 'repeat-error':  repeatError, 'disabled': disabled}, addClass]"
         v-clickoutside="selectClose">
        <div class="select-overlay" @click="selectToggle"></div>
        <span class="inp-s-label" v-if="!keywords" :class="{error: isError, 'repeat-error':  repeatError }">{{placeholder}}</span>
        <div v-if="tag==='div'" class="inp-s" :class="{error:isError, 'repeat-error':  repeatError }" v-html="keywords">

        </div>

        <input v-else :class="{error:isError, 'repeat-error':  repeatError }" type="text" v-model="keywords" name="" class="inp-s" :disabled="disabled">


        <div class="select-arrow"></div>
        <div class="select-list" :style="listStyle">
            <ul v-if="focus">
                <li v-for="(res,index) in options" :key="index" class="fs-item"
                    @click="change(index, res.name)" :class="{active: index === choosen, disabled: index > currentMonth}">
                    <span class="i i-ok2" v-if="keywords == res.name"></span>
                    <span v-html="res.name"></span>
                </li>
            </ul>
            <ul class="select-list --ghost" v-show="false">
                <li>
                    <div></div>
                </li>
                <li>
                    <div></div>
                </li>
                <li>
                    <div></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'InputSelectDefault',
        components: {},
        props: {
            tag: {default: ''},
            addClass: {default: ''},
            isError: {default: false},
            listStyle: {default: ''},
            options: {
                default() {
                    return [];
                }
            },
            modelValue: {default: ''},
            placeholder: {default: ''},
            valueIndex: {default: ''},
            modelId: {default: null},
            disabled: {default: false},
			choosedYear: {default: false},
        },
        model: {
            prop: 'modelId',
            event: 'change'
        },
        data: function () {
            return {
                keywords: '', //Слежение за инпутом
                resopened: false, //Отвечает за видимость списка результатов быстрого поиска
                choosen: null,
                focus: false,
                repeatError: false,
				currentYear: false,
				currentMonth: 13,
            }
        },
        mounted() {
            if(this.modelValue !==''){
                let option = this.options.find(option => option.id == this.modelValue);
                this.keywords = option ? option.name : '';
            }
            if(this.modelId !== null){
				let optionIndex = this.options.findIndex(option => option.id == this.modelId);
				let option = this.options[optionIndex];
				this.choosen = optionIndex;
                this.keywords = option ? option.name : '';
            }
            if(this.valueIndex != ''){
                this.change(this.valueIndex * 1);
            }
			if(this.choosedYear == 'Все'){
				this.currentMonth = 0
			}
        },
        emits: ['input','update:modelValue','open','change'],
        watch:{
            keywords(){
                this.$emit('update:modelValue',this.keywords);
            },
            modelValue(){
                this.keywords = this.modelValue
            },
            valueIndex(){
                if(this.valueIndex != ''){
                    this.change(this.valueIndex * 1);
                }else{
                    this.keywords = '';
                    this.choosen = null;
                }
            },
            isError(val,oldVal){
                if(oldVal){
                    this.repeatError = true;
                }
				if(val){
					if(this.$el.closest('.popup-wrapper')){
						let $top = 0;
						let pr = this.$el.closest('.popup-wrapper');
						$top += parseFloat(this.$refs.inpWrap.getBoundingClientRect().top + this.$el.closest('.popup-wrapper').scrollTop) - 10;
						pr.scrollTo({top: $top, left: 0, behavior: 'smooth'});
					}
				}
            },
            modelId(after){
                let option = this.options.find(option => option.id == after);
                this.keywords = option ? option.name : '';
            },
			choosedYear(val, oldVal){
				if(val && val != 'Все'){
					let currentDate = new Date();
					this.currentYear = currentDate.getFullYear();
					if(val == this.currentYear){
						this.currentMonth = currentDate.getMonth() + 1;
					} else if(+val < +this.currentYear) {
                        this.currentMonth = 13
                    }
				}else if(val == 'Все'){
					this.currentMonth = 0
				}
			}
        },
        computed: {
            focused() {
                return this.focus
            },
            showLabel() {
                return this.keywords.toString().length > 0;
            },
        },
        methods: {
            selectToggle(){
                if(!this.disabled){
                    this.focus = !this.focus;
                    this.$emit('open', this.focus);
                }
            },
            change: function(index, name){
                //this.keywords = this.options[index].name;
                this.focus = false;
                this.repeatError = false;
                this.choosen = index;
				this.keywords = name;
                this.$emit('change', this.options[index].id);
            },
            selectClose: function () {
                this.focus = false;
            },
        },
		directives: {
            'clickoutside': {
                mounted: function(el, binding, vNode) {
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
                        if (compName) { warn += `Found in component '${compName}'` }
                        //console.warn(warn);
                    }
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    }
                    el.__vueClickOutside__ = handler;
                    document.addEventListener('click', handler);
                },

                unmounted: function(el, binding) {
                    document.removeEventListener('click', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null;
                }
            }
        }
    }
</script>

<style lang="css">

</style>
