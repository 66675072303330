<template>
    <div class="content">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1">Сроки разработки</div>

            </div>
			<div>
                <input v-model="devPeriodPause" @change="changePause()" type="checkbox" id="checkbox">
                <label for="checkbox">Скрывать объекты На паузе</label>
            </div>
            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t">Объекты, которые находятся в работе и на паузе</span>
            </div>
        </div>
        <!-- Здесь пока ничего нет -->
        <nothing-block v-if="(activeSecondMenu.name || user.groupCode == 'T' || user.groupCode == 'P') && !requests.length && !isLoad"/>

        <section class="container" v-else>
            <div class="table">
                <div class="table__header">
                    <div class="header-elem text-size-ml text-bold text-color-white text-uppercase title" style="width: 200px">
                        Объект
                    </div>
                    <div class="header-elem text-center text-color-white" style="width: 100px">
						В работе
                    </div>
					<div class="header-elem text-center text-color-white" style="width: 160px;">
						Статус
                    </div>
					<div class="header-elem text-center text-color-white" style="width: 160px;">
						До сдачи
                    </div>
					<div class="header-elem text-center text-color-white" style="width: 160px;">
						Технолог
                    </div>
					<div class="header-elem text-center text-color-white" style="width: 160px;">
						Проверяющий
                    </div>
                </div>

                <div class="table__list">
                    <div class="table__row" v-for="request in requests" :key="request.id">
						<div class="table__item">
							<a href="javascript:void(0);" @click="searchQuery(request.name)">{{request.name}}</a>
						</div>
						<div class="table__item">
							{{request.daysPassed}} дн.
						</div>
						<div class="table__item">
							<div class="text-bold">{{request.sectionName}}</div>
							<div>{{request.statusName}}</div>
						</div>
						<div class="table__item">
							<span v-if="request.daysLeft >= 0">{{request.daysLeft}} дн.</span>
							<span class="text-color-red text-bold" v-else>Просрочен на {{Math.abs(request.daysLeft)}} дн.</span>
						</div>
						<div class="table__item">
							{{request.designer}}
						</div>
						<div class="table__item">
							{{request.inspector}}
						</div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>
<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Popup from '@/components/modal/Popup.vue';
import RequestsNew from '@/components/requests/RequestsNew.vue'
import RequestsWaitContract from '@/components/requests/RequestsWaitContract.vue'
import RequestsVerify from '@/components/requests/RequestsVerify.vue'
import RequestsPreApproved from '@/components/requests/RequestsPreApproved.vue'
import RequestsWaitingInterview from '@/components/requests/RequestsWaitingInterview.vue'
import RequestsRejected from '@/components/requests/RequestsRejected.vue'
import RequestsApproved from '@/components/requests/RequestsApproved.vue'
import RequestsList from "@/components/requests/RequestsList";
import RouteBlock from '@/components/RouteBlock.vue';
import NothingBlock from '@/components/NothingBlock.vue';
import RequestPopups from '@/components/RequestPopups.vue';

export default {
    name: "DevPeriod",
    props: {

    },
    components: {
        RequestsList,
        Tooltip,
        Popup,
        RequestsNew,
        RequestsWaitContract,
        RequestsVerify,
        RequestsPreApproved,
        RequestsWaitingInterview,
        RequestsRejected,
        RequestsApproved,
        RouteBlock,
        NothingBlock,
        RequestPopups,
    },
    data: function(){
        return {
			checkbox: false,
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.fetchData();
        });
    },
    computed: {
        ...mapState({
            itemTopActive: state => state.menus.itemTopActive,
            itemSecondActive: state => state.menus.itemSecondActive,
            menuSecond: state => state.menus.itemsSecond,
            popups: state => state.popups.items,
            requests: state => state.requests.items,
            isLoad: state => state.requests.isLoad,
            user: state => state.auth.user,
            menuList: state => state.menus.itemsSecondBuh,
            //devPeriodPause: state => state.requests.devPeriodPause,
        }),
        devPeriodPause: {
            get() {
                return this.$store.state.requests.devPeriodPause;
            },
            set(value) {
                this.$store.commit('requests/setDevPeriodPause', value);
            }
        },
        activeSecondMenu() {
            let code = '';
            let item = {};

            if (this.$route) {
                code = this.$route.path;
            }

            if (typeof code !== 'undefined') {
                code = code.split('/')[2];
            }

            for(let m in this.menuList){
                if(code == this.menuList[m].id){
                    item = this.menuList[m]
                }
            }

            if (!item.id) {
                if(this.$route.params.team){
                    item = {code: this.$route.params.team};
                }
                else{
                    item = {code: '1572'};
                }
            }

            return item
        },
    },
    methods: {
        fetchData () {
            this.$store.dispatch('requests/getList', {status: '', page: '', additionalElement: false, action:'devPeriod',  manager: this.activeSecondMenu.code, pause: this.devPeriodPause}).then(() => {});
        },
		searchQuery(val){
			this.$store.commit('requests/setSearchQuery', val);
			this.$store.commit('requests/setSearchIsOpen', true);
            this.$nextTick(()=>{
                this.$router.push('/search/');
            });
		},
        changePause(){
            this.fetchData();   
        }
    },
    watch: {
        '$route': 'fetchData'
    },
}
</script>
