<template>
    <div class="fill-specific">
        <div class="fill-specific__header">
            <div class="fill-specific__content">
                <div class="fill-specific__title">
                    Заполнить спецификацию по {{ currentItem?.name }}
                </div>
                <div class="fill-specific__btn">
                    <button class="btn" v-if="step === 1" @click="goToCheck">
                        <span class="i i-send mr4"></span>
                        продолжить
                    </button>
                    <button class="btn btn--trans" v-if="step === 2" @click="goToFillSpecific">
                        вернуться назад
                    </button>
                    <button class="btn" v-if="step === 2" @click="sendSpecific">
                        сохранить
                    </button>
                </div>
                <div class="fill-specific__close" @click="goBack" v-if="step === 1"></div>
            </div>
        </div>
        <div class="fill-specific__header fill-specific__header--plug"></div>
        <div class="fill-specific__content" v-if="!isLoading">
            <div v-show="step === 1">
                <object-features></object-features>
                <choose-room></choose-room>
                <name-rooms></name-rooms>
                <config-rooms></config-rooms>
                <button type="button" class="btn mt64" @click="goToCheck">продолжить</button>
            </div>
            <rooms-table v-if="step === 2"></rooms-table>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import ObjectFeatures from '@/components/fill-specific/ObjectFeatures.vue';
    import ChooseRoom from '@/components/fill-specific/ChooseRoom.vue';
    import NameRooms from '@/components/fill-specific/NameRooms.vue';
    import ConfigRooms from '@/components/fill-specific/ConfigRooms.vue';
    import RoomsTable from '@/components/fill-specific/RoomsTable.vue';

    export default {
        name: "FillSpecific",
        props: {

        },
        components: {
            ObjectFeatures,
            ChooseRoom,
            NameRooms,
            ConfigRooms,
            RoomsTable,
        },
        data: function(){
            return {
                isLoading: true,
            }
        },
        mounted(){
            if (!this.currentItem) {
                this.goBack();
                return;
            }
            this.$store.commit('configurator/setRequestId', +this.$route.params.id);
            this.getConfig({}).then(() => {
                this.getItemConfig().then(() => {
                    this.isLoading = false;
                });
            });
            window.onbeforeunload = function() {
                return "Data will be lost if you leave the page, are you sure?";
            };
        },
        unmounted() {
            window.onbeforeunload = null;
        },  
        computed: {
            ...mapState({
                currentItem: state => state.configurator.currentItem,
                step: state => state.configurator.step,
                isEditing: state => state.configurator.isEditing,
                isAutoSaving: state => state.configurator.isAutoSaving,
            }),
            ...mapGetters('configurator', [
                'finalConfig',
            ]),
        },
        methods: {
            ...mapActions('configurator', [
                'check', 'getConfig', 'send', 'getItemConfig', 'save',
            ]),
            goBack() {
                let accept = confirm('Внесенные вами изменения могут не сохраниться.');
                if (accept) {
                    this.$router.push(this.getBackPath());
                    this.$store.commit('configurator/setCurrentItem', null);
                }
            },
            goToCheck() {
                this.check();
            },
            goToFillSpecific() {
                this.$store.commit('configurator/setIsConfigEdited', false);
                this.$store.commit('configurator/setStep', 1);
            },
            sendSpecific() {
                if (!this.isEditing) {
                    this.send().then(() => {
                        this.$router.push(this.getBackPath());
                    });
                } else {
                    this.save().then(() => {
                        this.$router.push(this.getBackPath());
                    });
                }
                this.$store.commit('configurator/setIsConfigEdited', false);
            },
            getBackPath() {
                if (this.$route.params.back === 'give-design') {
                    return '/give-design';
                }
                return '/drawing/drawing_in_work/';
            }
        },
    }
</script>
<style scoped>
</style>
