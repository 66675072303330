<template>
    <div class="fill-specific__section">
        <div class="fill-specific__section-title">
            Отметьте особенности объекта
        </div>
        <div class="fill-specific__check-block" v-for="item in config?.no_room">
            <div class="fill-specific__check-block-title">{{ item.name }}</div>
            <div class="fill-specific__checkboxes">
                <template v-for="check in item.materials">
                    <template v-for="selector in check.selectors">
                        <checkbox-comp
                            :material_type_id="check.material_type_id"
                            :material_selector_id="selector.id"
                            :id="selector.id"
                            :label="selector.name"
                            :checked="selector.checked"
                            @add="add"
                            @remove="remove"
                            v-if="selector.name"></checkbox-comp>
                    </template>
                    <checkbox-comp @add="add" @remove="remove" :id="check.id" :material_type_id="check.material_type_id"
                                   :checked="check.checked" :label="check.material_type"
                                   v-if="check.material_type"></checkbox-comp>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import CheckboxComp from '@/components/forms/CheckboxComp.vue';
import { mapState } from 'vuex';

export default {
    name: 'ObjectFeatures',
    props: {},
    components: {
        CheckboxComp,
    },
    data: function () {
        return {
            array: [],
        };
    },
    mounted() {
        this.array = this.features ?? [];
    },
    computed: {
        ...mapState({
            features: state => state.configurator.features,
            config: state => state.configurator.config,
        }),
    },
    methods: {
        remove(obj) {
            if (this.array.length) {
                const idx = this.array.findIndex(item => item.material_type_id === obj.material_type_id && item.material_selector_id === obj.material_selector_id);
                if (idx !== -1) {
                    this.array.splice(idx, 1);
                }
                this.$store.commit('configurator/setFeatures', this.array);
            }
        },
        add(obj) {
            const idx = this.array.findIndex(item => item.material_type_id === obj.material_type_id && item.material_selector_id === obj.material_selector_id);
            if (idx === -1) {
                this.array.push(obj);
                this.$store.commit('configurator/setFeatures', this.array);
            }
        },
    },
};
</script>
