<template>
    <div class="fill-specific">
        <div class="fill-specific__header">
            <div class="fill-specific__content">
                <div class="fill-specific__close" @click="goBack"></div>
            </div>
        </div>
        <div class="fill-specific__header fill-specific__header--plug"></div>
        <div class="fill-specific__content">
            <rooms-table v-if="currentItem" :is-check="true"></rooms-table>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import RoomsTable from '@/components/fill-specific/RoomsTable.vue';

    export default {
        name: "CheckSpecific",
        props: {

        },
        components: {
            RoomsTable,
        },
        data: function(){
            return {

            }
        },
        mounted(){
            if (!this.currentItem) {
                this.goBack();
                return;
            }
            this.$store.commit('configurator/setRequestId', +this.$route.params.id);
            this.getConfig().then(() => {
                this.getItemConfig();
            });
        },
        computed: {
            ...mapState({
                currentItem: state => state.configurator.currentItem,
            }),
        },
        methods: {
            ...mapActions('configurator', [
                'getConfig','getItemConfig'
            ]),
            goBack() {
                this.$router.push(this.getBackPath());
                this.$store.commit('configurator/setCurrentItem', null);
            },
            getBackPath() {
                if (this.$route.params.back === 'give-design') {
                    return '/give-design';
                }
                return '/drawing/drawing_in_work/';
            }
        },
    }
</script>
<style scoped>
</style>
