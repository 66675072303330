<template>
    <div class="fill-specific__section"  :class="{'--error': haveFieldError}">
        <div class="fill-specific__section-title">
            Назовите помещения
        </div>
        <div class="fill-specific__name-rooms">
            <template v-for="(category, index) in config?.room_groups" :key="index">
                <template v-for="(room, roomIndex) in category.rooms" :key="`room-${roomIndex}`" >
                    <div v-for="(field, fieldIndex) in room.client_rooms" :key="field.id" :id="`${field.id}-input`" class="fill-specific__input">
                        <input-styled v-model="field.name"
                            :maxLengthNum="32"
                            @focus="handleFocus(`${field.id}-input`)"></input-styled>
                        <div class="trash" @click="removeField({ category, room, field })"> </div>
                        <div class="room-name" :class="{'error': field.isError}">{{ room.name }}</div>
                    </div>
                </template>
            </template>
            <div class="choose-one">Выберите хотя бы одно помещение</div>
        </div>
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import InputStyled from '@/components/forms/InputStyled.vue';

    export default {
        name: "NameRooms",
        props: {
        },
        components: {
            InputStyled,
        },
        data: function(){
            return {
                
            }
        },
        mounted(){

        },
        computed: {
            ...mapState({
                haveFieldError: state => state.configurator.haveFieldError,
                config: state => state.configurator.config,
            }),

        },
        methods: {
            ...mapActions('configurator', [
                'addField', 'removeError', 'removeField'
            ]),
            removeError(id) {
                const fieldWrapper = document.getElementById(id),
                field = fieldWrapper.getElementsByTagName('input')[0];
                field.classList.remove('error', 'repeat-error');
                fieldWrapper.classList.remove('--error');
            },
            handleFocus(id) {
                let fieldWrapper = document.getElementById(id);
                let field = fieldWrapper.getElementsByTagName('input')[0];

                setTimeout(() => {
                    field.select();
                });

                this.removeError(id);
            },
        },
    }
</script>
