<template>
    <div>
      <div class="popup-info popup-icon-info">
          <svg class="big-icon" style="width: 48px; height: 48px;">
            <use xlink:href="@/assets/img/big-icons-sprite.svg#cat"></use>
          </svg>

          <div class="text mt24">
              <div class="text-size-l text-bold info-title text-color-red">Заблокировать пользователя?</div>

              <p class="info-text text-color-red mt8">
                 Заблокировать пользователя? Это действие нельзя отменить!
              </p>
          </div>

          <div class="df df-center mt24">
             <submit-btn @click="submit()" :type="btnType"><span class="i mr4" :class="{'i-ok7': btnType=='normal'}"></span>Подтвердить</submit-btn>
          </div>
      </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import SubmitBtn from '@/components/forms/SubmitBtn.vue';

    export default {
        name: "BlockWorker",
        methods: {
            close: function () {
                this.$store.commit('popups/close', {name: this.popups.blockWorker.name});
            },
            submit() {
                this.btnType = 'load';
                this.$store.dispatch('staff/blockUser',{id: this.editUser.id}).then(() => {
                    this.btnType = 'ok';
                    setTimeout(()=>{
                        this.btnType = 'normal';
                        this.$store.commit('popups/close', {name: this.popups.blockWorker.name});
                        window.location.reload();
                    }, this.options.delayPopupClose);
                },()=>{ this.btnType = 'normal'; });
            },
        },
        data(){
           return {
               btnType: 'normal'
           }
        },
        components:{
            SubmitBtn,
        },
        computed: {
            ...mapState({
                popups: state => state.popups.items,
                options: state => state.options,
                editUser: state => state.staff.editUser,
            }),
        }
    }
</script>

<style scoped>

</style>
