<template>
    <div class="fill-specific__section">
        <div class="fill-specific__section-title">
            <span>Выберите помещения</span>
            <div class="fill-specific__note">
                Добавляйте помещения в том порядке, в котором написано в Рулесах.<br>Чтобы добавить помещение несколько раз, нажмите на кнопку несколько раз.
            </div>
        </div>
        <div class="fill-specific__choose-block">
            <div class="fill-specific__choose-rooms" v-for="item in config?.room_groups" :key="item.name">
                <div class="fill-specific__rooms-name">{{ item.name }}</div>
                <div class="fill-specific__choose-items">
                    <div class="fill-specific__choose-item" v-for="(room, index) in item.rooms" :key="room.name" @click="addField(room)">
                        {{ room.name }}
                        <span class="fill-specific__count" v-if="room.client_rooms?.length">{{ room.client_rooms?.length }}</span>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
    import { mapState, mapActions } from 'vuex';
    import CheckboxComp from '@/components/forms/CheckboxComp.vue';
    export default {
        name: "ObjectFeatures",
        props: {
        },
        components: {
            CheckboxComp,
        },
        data: function(){
            return {
                
            }
        },
        mounted(){

        },
        computed: {
            ...mapState({
                config: state => state.configurator.config,
            }),

        },
        methods: {
            addField(room) {
                this.$store.commit('configurator/setIsConfigEdited', true);
                this.$store.commit('configurator/addField', { room });
            }
        },
    }
</script>
