<template>
    <div class="checkbox-comp">
        <input type="checkbox" :checked="isChecked" :id="id" @change="toggleCheck" :disabled="isDisabled">
        <label :for="id">{{ label }}</label>
        <tumbler :options="items" :elem-width="false" v-model="type.value" :id="`${id}tumbler`" v-if="items.length"
                 @change="tumblerChanged"/>
    </div>
</template>

<script>
import Tumbler from '@/components/Tumbler.vue';

export default {
    props: {
        label: '',
        id: '',
        modelValue: false,
        isDisabled: {
            default: () => false,
        },
        name: '',
        typeId: undefined,
        items: undefined,
        required: false,
        isEditing: false,
        isConfigEdited: false,
        checked: false,
    },
    components: {
        Tumbler,
    },
    data() {
        return {
            isChecked: false,
            options: false,
            checkedCheckboxes: false,
            type: { value: false, error: false },
        };
    },
    watch: {
        modelValue(val) {
            this.checkedCheckboxes = val;
        },
        isChecked(val) {
            if (val) {
                let obj = { material_type_id: +this.typeId };
                if (this.items.length) {
                    obj.material_selector_id = +this.type.value.id ?? null;
                }
                if (!this.checkedCheckboxes.some((item) => item.material_type_id === this.typeId)) {
                    this.checkedCheckboxes.push(obj);
                }
            } else {
                this.checkedCheckboxes = this.checkedCheckboxes.filter(item => item.material_type_id !== this.typeId);
                this.type = { value: false, error: false };
            }
            this.$emit('update:modelValue', this.checkedCheckboxes);
        },
    },
    methods: {
        toggleCheck() {
            this.isChecked = !this.isChecked;
        },
        tumblerChanged() {
            this.checkedCheckboxes = this.checkedCheckboxes.map(obj => {
                if (obj.material_type_id === this.typeId) {
                    return { ...obj, material_selector_id: +this.type.value.id };
                } else {
                    return obj;
                }
            });
            this.isChecked = true;
            document.getElementById(`${this.id}tumbler`).classList.remove('repeat-error', '--error', 'error');
            this.$emit('update:modelValue', this.checkedCheckboxes);
        },
        setChecked() {
            if (this.required || (this.checked && this.isEditing && this.isConfigEdited ) || (this.checked && !this.isEditing)) {
                this.isChecked = true;
                let currentCheck = this.modelValue.find(obj => obj.material_type_id === this.typeId);
                if (currentCheck && 'material_selector_id' in currentCheck) {
                    this.type.value = { id: +currentCheck.material_selector_id };
                }
            } else {
                let currentCheck = this.modelValue.find(obj => obj.material_type_id === this.typeId);
                this.isChecked = !!currentCheck;
                if (currentCheck && 'material_selector_id' in currentCheck) {
                    this.type.value = { id: +currentCheck.material_selector_id };
                }
            }
        },
    },
    mounted() {
        this.checkedCheckboxes = this.modelValue;
        setTimeout(() => {
            this.setChecked();
        });
    },
};
</script>
