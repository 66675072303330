<template>
    <div class="checkbox-comp">
      <input type="checkbox" :checked="isChecked" :id="id" @change="toggleCheck" :disabled="isDisabled">
      <label :for="id">{{ label }}</label>
      <template v-if="child">
        <div class="checkbox-comp">
          <input type="checkbox" :checked="isChildChecked" :id="child.id" @change="toggleChildCheck()" :disabled="child.isDisabled">
          <label :for="child.id">{{ child.material_type }}</label>
        </div>
      </template>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        label: '', 
        id: '',
        modelValue: false,
        child: {
            default: () => false,
        },
        isDisabled: {
          default: () => false
        },
        name: '',
        checked: {
          default: () => false
        },
        material_type_id: {
          default: () => false
        },
        material_selector_id: {
          default: () => false
        },
    },
    components: {
    },
    data() {
      return {
        isChecked: false,
        options: false,
        isChildChecked: false,
        checkedItems: [],
      };
    },
    watch: {
      isChecked(val) {
        if (val) {
          this.$emit('add', this.obj);
        } else {
          this.$emit('remove', this.obj);
        }
      },
      isChildChecked(val) {
        if (val) {
          this.$emit('add', this.childObj);
        } else {
          this.$emit('remove', this.childObj);
        }
      },
    },
    computed: {
      obj(){
        let obj = { material_type_id: this.material_type_id };
        if ( this.material_selector_id ) {
          obj.material_selector_id = this.material_selector_id;
        }
        return obj;
      },
      childObj() {
        let obj = { material_type_id: this.child.material_type_id };
        if ( this.child.material_selector_id ) {
          obj.material_selector_id = this.child.material_selector_id;
        }
        return obj;
      },
    },
    methods: {
      toggleCheck() {
        this.isChecked = +!this.isChecked;
        if (!this.isChecked && this.isChildChecked) {
          this.toggleChildCheck();
        }
      },
      toggleChildCheck() {
        this.isChildChecked = +!this.isChildChecked;
        this.isChecked = this.isChildChecked ?? this.isChecked;
      },
    },
    mounted() {
        this.isChecked = this.checked;
        this.isChildChecked = this.child.checked;
    },
  };
  </script>
  