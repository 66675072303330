<template>
    <div class="" v-if="section">
        <div class="rules__header">
            <div class="text-size-ml text-bold rules__header-wrapper df-space-between">
                <div class="rules__header-left">
					<span class="ts-name --staff">
						<span>Команда</span>
					</span>
                </div>
                <div class="rules__header-right">
					<span class="ss-name">
						{{section.name}}
					</span>
					<switch-elem
                        :name="tumblers.editable.name"
                        :value="tumblers.editable.value"
                        :icon="'i i-pencil2'"
                    >
					</switch-elem>
                    <div class="rules__btn" @click="openWorkerModal({})">
                        <span class="elem">
                             <span class="i i-plus6"></span>
                            Добавить сотрудника
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="content content--team">
            <div class="container container--staff">
                <aside-sticky :marginTop="56" :marginBottom="0">
                    <aside class="aside aside--left aside--catalog">
                        <div class="nav --staff">
                            <div class="nav-wrapper">
                                <ul class="nav-list">
                                    <li class="nav-list-item" v-for="sect in sections">


                                        <router-link href="javascript:void(0)"
                                                     class="nav-list-item-link"
                                                     :to="'/staff/' + sect.code"
                                                     :class="{active: sect.code == currentCode, 'can-hover': false, 'current': sect.code == section.code }">
                                            <span class="cat-name">{{ sect.title }}</span>
                                            <div class="count" v-if="false">
                                                {{ sect.count }}
                                            </div>
                                        </router-link>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </aside>
                </aside-sticky>
                <div class="container__full container-scroll container__right">
                    <div class="workers-list__table-head">
                        <div class="text-size-xs text-uppercase text-color-grey mr8" style="width: 365px;">
                            Пользователь
                        </div>
                        <div class="text-size-xs text-uppercase text-color-grey mr8 text-center" style="width: 254px;">
                            Email
                        </div>
                        <div class="text-size-xs text-uppercase text-color-grey text-right" style="width: 102px">
                            Телефон
                        </div>
                    </div>

                    <div class="workers-list">
                        <div class="worker" v-for="user in section.users">
                            <div class="worker-name">
                                <span class="text-size-m">{{ user.lastName }} {{ user.firstName }} {{ user.secondName }}</span>
                                <div class="text-color-grey text-size-s">
                                    {{ user.post }} {{ user.groupCode }}{{ user.number }}
                                </div>
                            </div>
                            <div class="worker-email">
                                {{ user.email }}
                            </div>
                            <div class="worker-phone">
                                {{ user.phone }}
                            </div>
                            <span class="i-wrapper" v-if="tumblers.editable.value" @click="openWorkerModal(user)">
								<span class="i i-pencil2"></span>
								<span class="i i-pencil2-active"></span>
							</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <popup :is-set-styled="false" :show="popups.worker.show" :name="popups.worker.name" :width="800"
               :add-class="'staff-modal'">
            <worker-modal @updateList="reloadList()"/>
        </popup>

        <popup :is-set-styled="false" :show="popups.blockWorker.show" :name="popups.blockWorker.name" :width="400"
               :add-class="'submit-modal'">
            <block-worker/>
        </popup>

        <popup :is-set-styled="false" :show="popups.unBlockWorker.show" :name="popups.unBlockWorker.name" :width="400"
               :add-class="'submit-modal'">
            <un-block-worker/>
        </popup>
    </div>
</template>

<script>
import {mapState} from 'vuex';
import AsideSticky from '@/components/AsideSticky.vue';
import Popup from '@/components/modal/Popup.vue';
import SwitchElem from '@/components/SwitchElem.vue';
import WorkerModal from '@/components/modal/WorkerModal.vue';
import BlockWorker from '@/components/modal/BlockWorker.vue';
import UnBlockWorker from '@/components/modal/UnBlockWorker.vue';
import Tooltip from '@/components/Tooltip.vue';
import VueFooter from '@/components/VueFooter.vue';

export default {
    name: 'Staff',
    components: {
        AsideSticky,
        Popup,
        Tooltip,
        VueFooter,
        WorkerModal,
        BlockWorker,
        UnBlockWorker,
		SwitchElem
    },
    data: function () {
        return {}
    },
    beforeUnmount() {
        document.querySelector('body').classList.remove('staff-page');
    },
    mounted() {
        document.querySelector('body').classList.add('staff-page');
        this.init();
    },
    computed: {
        ...mapState({
            popups: state => state.popups.items,
            sections: state => state.staff.sections,
            section: state => state.staff.section,
			tumblers: state => state.tumblers.items,
        }),
        sectionCode() {
            return this.section.code;
        },
        currentCode() {
            let code = this.$route.params.status;

            if (this.sections.length) {

                if (!code) {
                    code = this.sections[0].code;
                }

            }
            return code;
        },
    },
    methods: {
        reloadList(){
            this.openSect();
        },
        openWorkerModal(item) {
            this.$store.dispatch('staff/edit', {user: item}).then(() => {
                this.$store.commit('popups/open', {name: this.popups.worker.name});
            });
        },
        init() {
            this.fetchData();
            this.$nextTick(() => {
                this.openSect();
            });
        },
        fetchData() {
            this.$nextTick(() => {
                this.$store.dispatch('staff/getSections').then(() => {

                });
            });
        },
        openSect() {
            this.$nextTick(() => {
                this.$store.dispatch('staff/getSection', {sect: this.currentCode}).then(() => {

                });
            });
        },
    },
    watch: {
        'currentCode': 'openSect'
    },
}
</script>
